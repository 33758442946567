import React, { createContext, useContext, useReducer } from 'react'

const initialState = {
    menuMode: 'dark',
    dotActive: 'welcome-section'
}

const stateReducer = (state, payload) => {
    switch (payload.action) {
        case 'SET_MENUE_DARK':
            return {...state, menuMode: 'dark'}
        case 'SET_MENUE_LIGHT':
            return { ...state, menuMode: 'light' }
        case 'SET_DOT_ACTIVE':
            return { ...state, dotActive: payload.data}
        default:
            return state
    }
}

export const StateContext = createContext({})

const StateContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(
        stateReducer,
        initialState
    );

    const StateContextStore = {
        state,
        dispatch,
    }

    return <StateContext.Provider value={StateContextStore}>{children}</StateContext.Provider>
}
export default StateContextProvider

export const useStateContext = () => useContext(StateContext)