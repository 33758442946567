import React, { useEffect, useState } from "react"
import tw, { styled } from "twin.macro"

import Logo from "@root/Logo"
import { motion } from "framer-motion"
import { navigate, Link } from "gatsby"
import throttle from "lodash-es/debounce"
import { useStateContext } from "@context/stateContext"
import { globalHistory as history } from "@reach/router"

const isBrowser = () => typeof window !== "undefined"

const DesktopNavigationContainer = styled(motion.div)`
  ${tw`absolute inset-0 text-white font-primary`}
  height: 135px;
`

const SiteTitle = styled(motion.span)`
  ${tw`absolute z-10 pl-10 font-black uppercase pt-[3.25rem] drop-shadow-xl`}
`

const Nav = styled.nav`
  ${tw`absolute flex flex-row items-center justify-end w-full pr-10 pt-[3.25rem]`}
  ${({ windowHeight }) => `height: ${windowHeight}px`}
`

const Ul = styled(motion.ul)`
  ${tw`flex flex-row`}
`

// TODO: Integrate menue font-sizes in Tailwind Theme

const Li = styled(motion.li)`
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 2rem;

  &::after {
    content: "";
    ${tw`transition duration-500 ease-in-out`}
  }
  &.menu-active {
    ${tw`relative`}

    &::after {
      ${tw`absolute bottom-0 left-0 right-0 h-px bg-current`}
    }
  }
`
const variantsNavigationContainer = {
  menuHidden: {
    backgroundColor: "rgba(255,255,255,0)",
    backdropFilter: "blur(50px) opacity(0)",
  },
  menuVisible: {
    backgroundColor: "rgba(255,255,255,.5)",
    backdropFilter: "blur(50px) opacity(1)",
  },
  menuHiddenDark: {
    backgroundColor: "rgba(0,0,0,0)",
    backdropFilter: "blur(50px) opacity(0)",
  },
  menuVisibleDark: {
    backgroundColor: "rgba(0,0,0,.5)",
    backdropFilter: "blur(50px) opacity(1)",
  },
}
const variantsSiteTitle = {
  menuHidden: { opacity: 0 },
  menuVisible: { opacity: 1 },
}
const variantsMenu = {
  menuHidden: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: 1,
      duration: 0.4,
      type: "sping",
    },
  },
  menuVisible: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
      duration: 0.6,
    },
  },
}

const variantsMenuItems = {
  menuHidden: {
    y: -80,
    opacity: 0,
  },
  menuVisible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      ease: [0.17, -0.05, 0.26, 0.67],
    },
  },
}

//* ---------------------------
//* Component  DesktopNavigation
//* ---------------------------

function DesktopNavigation({ menuItems }) {
  const stateContext = useStateContext()
  const [menuHidden, setMenuHidden] = useState(false)
  const [scrollOffset, setScrollOffset] = useState(true)
  const { location } = history
  let oldScrollY = 0
  const handelMenuVisibility = () => {
    setMenuHidden(oldScrollY < window.scrollY && window.scrollY >= 200 ? true : false)
    setScrollOffset(window.scrollY >= 200 ? false : true)
    oldScrollY = window.scrollY
  }
  const onWindowScroll = throttle(handelMenuVisibility, 50, { trailing: true })
  useEffect(() => {
    isBrowser() && window.addEventListener("scroll", onWindowScroll)
    return () => isBrowser() && window.removeEventListener("scroll", onWindowScroll)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const navigateToRoute = path => {
    navigate(path)
  }
  return (
    <DesktopNavigationContainer
      initial="menuVisible"
      // animate={menuHidden || scrollOffset ? "menuHidden" : "menuVisible"}
      animate={
        (menuHidden && stateContext.state.menuMode === "dark") || (scrollOffset && stateContext.state.menuMode === "dark")
          ? "menuHiddenDark"
          : (menuHidden && stateContext.state.menuMode !== "dark") || (scrollOffset && stateContext.state.menuMode !== "dark")
          ? "menuHidden"
          : !menuHidden && stateContext.state.menuMode === "dark"
          ? "menuVisibleDark"
          : "menuVisible"
      }
      variants={variantsNavigationContainer}
      className={menuHidden && "pointer-events-none"}
    >
      <SiteTitle animate={menuHidden ? "menuHidden" : "menuVisible"} variants={variantsSiteTitle} css={stateContext.state.menuMode === "dark" ? tw`text-white` : tw`text-black`}>
        <Link to="/">Schmitts Katze</Link>
      </SiteTitle>
      <div onClick={e => navigateToRoute("/")}>
        <Logo isDark={true} enlarged={true} isMobile={false} />
      </div>
      <Nav css={stateContext.state.menuMode === "dark" ? tw`text-white` : tw`text-black`}>
        <Ul animate={menuHidden ? "menuHidden" : "menuVisible"} variants={variantsMenu}>
          {menuItems.map(item => {
            return (
              <Li variants={variantsMenuItems} key={item.id} className={location.pathname === item.path ? "menu-active" : ""}>
                <Link to={item.path}>{item.label}</Link>
              </Li>
            )
          })}
        </Ul>
      </Nav>
    </DesktopNavigationContainer>
  )
}

export default DesktopNavigation
