import { AnimateSharedLayout, AnimatePresence, motion } from "framer-motion"

import NavBar from "@root/NavBar"
import Footer from "@root/Footer"
import PropTypes from "prop-types"
import React from "react"
import StateContextProvider from "@context/stateContext"
import smoothscroll from "smoothscroll-polyfill"
import CookieContainer from "@elements/CookieContainer"

const mainVariants = {
  in: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.08,
      staggerDirection: 1,
    },
  },
  out: {
    transition: {
      when: "afterChildren",
      staggerChildren: 0.08,
      staggerDirection: -1,
    },
  },
}

const Layout = ({ children }) => {
  const isBrowser = () => typeof window !== "undefined"
  isBrowser() && smoothscroll.polyfill()
  const is404 = children.props?.pageResources?.page?.path === "/404.html"
  return (
    <StateContextProvider>
      <NavBar />
      {!!is404 ? (
        children
      ) : (
        <AnimateSharedLayout>
          <AnimatePresence exitBeforeEnter initial={false}>
            <motion.main
              variants={mainVariants}
              key={`motion-${Math.random(0, 99999)}`}
            >
              {children}
              <motion.div
                className="fixed top-0 left-0 w-full min-h-screen bg-black"
                initial={{
                  opacity: 0.98,
                  zIndex: 10,
                  display: "block",
                }}
                animate={{
                  opacity: 0,
                  transition: {
                    duration: 0.5,
                    ease: [0.3, 0.01, 0.26, 0.9],
                  },
                  transitionEnd: {
                    zIndex: -1,
                    display: "none",
                  },
                }}
                exit={{
                  opacity: 0.98,
                  zIndex: 10,
                  display: "block",
                  transition: {
                    duration: 0.5,
                    ease: [0.3, 0.01, 0.26, 0.9],
                  },
                }}
              ></motion.div>
            </motion.main>
          </AnimatePresence>
        </AnimateSharedLayout>
      )}
      <Footer />
      <CookieContainer />
    </StateContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
