import React, { useState } from "react"
import tw, { styled } from "twin.macro"

import Logo from "@root/Logo"
import { motion } from "framer-motion"
import { navigate, Link } from "gatsby"
import { useWindowSize } from "@hooks/useWindowSize"
import { globalHistory as history } from "@reach/router"

const MobileNavigationContainer = styled(motion.div)`
  ${tw`absolute inset-0 overflow-hidden text-white bg-black font-primary`}
  height: 80px;
`

const ToggleMenuButton = styled(motion.button)`
  ${tw` focus:outline-none`}
  position: absolute;
  right: 20px;
  top: 30px;
  font-size: 16px;
`
const CloseMenuButton = styled(motion.button)`
  ${tw` focus:outline-none`}
  width: 26px;
  height: 26px;
`

const Nav = styled.nav`
  ${tw`absolute flex flex-col items-center w-full px-5 pt-32 justify-evenly md:pt-40`}
  ${({ windowHeight }) => `height: ${windowHeight}px`}
`

const Ul = styled.ul`
  ${tw`flex flex-col items-center`}
`

// TODO: Integrate menue font-sizes in Tailwind Theme
const Li = styled(motion.li)`
  ${tw`inline-flex text-3xl font-black leading-tight text-center uppercase cursor-pointer md:text-6xl md:leading-normal`}
  &::after {
    content: "";
    ${tw`transition duration-500 ease-in-out`}
  }
  &.menu-active {
    ${tw`relative`}

    &::after {
      ${tw`absolute bottom-0 left-0 right-0 h-px bg-current`}
    }
  }
`

const variantsContainer = {
  menuOpen: ({ height }) => ({
    height: height,
    transition: {
      type: "spring",
      velocity: 10,
      duration: 0.2,
      when: "beforeChildren",
      staggerChildren: 0.1,
      delayChildren: -0.2,
    },
  }),
  menuClosed: {
    height: 80,
    transition: {
      type: "spring",
      velocity: 10,
      duration: 0.2,
      when: "afterChildren",
      staggerChildren: 0.07,
      delayChildren: 0,
      staggerDirection: -1,
    },
  },
}

const variantsListItem = {
  menuOpen: {
    opacity: 1,
    transition: {
      duration: 0.5,
      stagger: 0.07,
    },
  },
  menuClosed: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
}

const variantsCloseButton = {
  menuOpen: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
    },
  },
  menuClosed: {
    opacity: 0,
    scale: 0,
    transition: {
      delay: 0,
    },
  },
}

//* ---------------------------
//* Component  MobileNavigation
//* ---------------------------

function MobileNavigation({ menuItems, isMobile }) {
  const [menuOpen, setMenuOpen] = useState(false)
  const windowSize = useWindowSize()
  const { location } = history
  const navigateToRoute = path => {
    setMenuOpen(false)
    setTimeout(() => {
      navigate(path)
    }, 200)
  }

  if (menuOpen) {
    document.body.style.overflow = "hidden"
    document.body.style.touchAction = "none"
  } else {
    document.body.style.overflow = "unset"
    document.body.style.touchAction = "auto"
  }

  return (
    <MobileNavigationContainer custom={{ height: windowSize.height }} initial="menuClosed" animate={menuOpen ? "menuOpen" : "menuClosed"} variants={variantsContainer}>
      <div onClick={e => navigateToRoute("/")}>
        <Logo enlarged={menuOpen} isDark={!menuOpen} isMobile={true} />
      </div>
      <Nav windowHeight={windowSize.height}>
        <Ul>
          {menuItems.map(item => {
            return (
              <Li key={item.id} variants={variantsListItem} className={location.pathname === item.path ? "menu-active" : ""}>
                <Link to={item.path}>{item.label}</Link>
              </Li>
            )
          })}
        </Ul>
        <CloseMenuButton onClick={() => setMenuOpen(false)} variants={variantsCloseButton}>
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L24 24" stroke="#E6FF00" strokeWidth="4" />
            <path d="M24 2L2 24" stroke="#E6FF00" strokeWidth="4" />
          </svg>
        </CloseMenuButton>
      </Nav>
      <ToggleMenuButton
        onClick={() => setMenuOpen(true)}
        animate={{
          y: menuOpen ? 40 : 0,
          opacity: menuOpen ? 0 : 1,
          transition: { delay: menuOpen ? 0 : 0.45 },
        }}
      >
        Menu
      </ToggleMenuButton>
    </MobileNavigationContainer>
  )
}

export default MobileNavigation
