const defaultTheme = require("tailwindcss/defaultTheme")
module.exports = {
  mode: 'jit',
  purge: {
    content: [
      "./src/**/*.html",
      "./src/**/*.js",
      "./src/**/*.jsx",
      "./public/**/*.html",
    ],
    options: {
      safelist: ['transform'],
    },
  },
  
  darkMode: "class", // or 'media' or 'class'
  theme: {
    screens: {
      xs: "480px",
      ...defaultTheme.screens,
      fhd: "1920px",
    },
    fontFamily: {
      primary: ["Raleway", "serif"],
      copy: ["Merriweather", "sans-serif"],
      display: ["Lobster"],
      // 'sans': ['ui-sans-serif', 'system-ui', ...],
    },
    extend: {
      colors: {
        transparent: "transparent",
        current: "currentColor",
        yellow: {
          light: "#EDFF47",
          DEFAULT: "#E6FF00",
          dark: "#DCF500",
        },
        blue: {
          light: "#1F1FFF",
          DEFAULT: "#0000FF",
          dark: "#0000E0",
        },
        piggy: {
          light: "#FFC2E9",
          DEFAULT: "#FFABE0",
          dark: "#FF99DA",
        },
        pink: {
          light: "#FF1FAD",
          DEFAULT: "#FF00A1",
          dark: "#F5009B",
        },
        green: {
          light: "#33FF33",
          DEFAULT: "#00FF00",
          dark: "#00E000",
        },
        customGray: "#3C3C3C",
      },
      spacing: {
        skOffset: "7.5%",
        skOffsetDouble: "15%",
        100: "100px",
        150: "150px",
      },
      height: {
        "25vw": "25vw",
        "35vw": "35vw",
        "50vw": "50vw",
        "60vw": "60vw",
        "75vw": "75vw",
        "100vw": "100vw",
        "90vh": "90vh",
        "95vh": "95vh",
        stickersBoxedSm: "700px",
        stickersBoxedMd: "600px",
        stickersFullSm: "1250px",
        stickersFullMd: "1000px",
        logoNormalSize: "140px",
        logoLeaSize: "70px",
        "150pr": "150%",
        "200pr": "200%",
      },
      minHeight: {
        "25vw": "25vw",
        "35vw": "35vw",
        "50vw": "50vw",
        "60vw": "60vw",
        "75vw": "75vw",
        "100vw": "100vw",
        auto: "auto",
        "500px": "500px",
        "600px": "600px",
        "700px": "700px",
        "800px": "800px",
        "900px": "900px",
        "1000px": "1000px",
      },
      maxHeight: {
        "25vw": "25vw",
        "35vw": "35vw",
        "50vw": "50vw",
        "60vw": "60vw",
        "75vw": "75vw",
        "100vw": "100vw",
      },
      width: {
        "25vw": "25vw",
        "35vw": "35vw",
        "50vw": "50vw",
        "60vw": "60vw",
        "75vw": "75vw",
        "100vw": "100vw",
        logoNormalSize: "140px",
        logoLeaSize: "70px",
      },
      maxWidth: {
        "25vw": "25vw",
        "35vw": "35vw",
        "50vw": "50vw",
        "60vw": "60vw",
        "75vw": "75vw",
        "100vw": "100vw",
      },
      scale: {
        200: "2",
        250: "2.5",
        300: "3",
        350: "3.5",
        400: "4",
        450: "4.5",
      },
      fontSize: {
        200: ["200px", "1"],
        160: ["160px", "185px"],
        120: ["120px", "1"],
        90: ["90px", "1"],
        80: ["80px", "90px"],
        60: ["60px", "64px"],
        46: ["46px", "50px"],
        32: ["32px", "42px"],
        24: ["24px", "32px"],
        20: ["20px", "28px"],
        18: ["18px", "24px"],
        14: ["14px", "20px"],
        12: ["12px", "16px"],
        "10xl": ["10rem", "1"],
        "11xl": ["12rem", "1"],
        "12xl": ["14rem", "1"],
      },
    },
  },
  plugins: [
    // require('@tailwindcss/typography'),
    // require('@tailwindcss/forms'),
    // require('@tailwindcss/line-clamp'),
    require('@tailwindcss/aspect-ratio'),
  ]
}
