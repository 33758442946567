import tw, { styled } from 'twin.macro';
import React from "react"
import { motion } from "framer-motion"

const LogoContainer = styled(motion.div)`
  ${tw`
    absolute
    flex
    flex-row
    justify-center
    cursor-pointer
    2xl:h-logoNormalSize
    xl:h-logoLeaSize
    md:h-logoNormalSize
    2xl:w-logoNormalSize
    xl:w-logoLeaSize
    md:w-logoNormalSize
    h-20
    w-20
    inset-0
    top-0
    left-0
    right-0
    mx-auto
    z-10
    pointer-events-auto
`}
  
`

const Svg = styled(motion.svg)`
    ${tw`
      h-full
      object-left-top
    `}
`

const variantsLogoContainer = {
  enlarged: ({ y }) => ({
    y: y,
    transition: {
      ease: 'easeInOut',
      duration: 0.2
    }
  }),
  notEnlarged: {
    height: 80,
    width: 80,
    y: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.3,
      delay: 0.3
    }
  }
}

const variantsStrokeColor = {
  light:{
    stroke: '#000',
    transition: {
      ease: 'easeInOut',
      duration: 0.2
    }
  },
  dark:{
    stroke: '#FFF',
    transition: {
      ease: 'easeInOut',
      duration: 0.3,
      delay: 0.3
    }
  },
}

const variantsBackgroundColor = {
  light:{
    fill: '#FFF',
    transition: {
      ease: 'easeInOut',
      duration: 0.2
    }
  },
  dark:{
    fill: '#000',
    transition: {
      ease: 'easeInOut',
      duration: 0.3,
      delay: 0.3
    }
  },
}

const variantsFillColor = {
  light:{
    fill: '#000',
    transition: {
      ease: 'easeInOut',
      duration: 0.2
    }
  },
  dark:{
    fill: '#FFF',
    transition: {
      ease: 'easeInOut',
      duration: 0.3,
      delay: 0.6
    }
  },
}

const Logo = ({ enlarged, isDark, isMobile }) => {
  return (
    <LogoContainer
      custom={{ y: isMobile ? 20 : 35 }}
      animate={enlarged ? 'enlarged' : 'notEnlarged'}
      variants={ variantsLogoContainer }
    >
      <Svg viewBox="0 0 140 140" fill="none" animate={isDark ? 'dark' : 'light'} variants={ variantsStrokeColor } xmlns="http://www.w3.org/2000/svg">
              <defs>
                <g id="left-whiskers">
                    <path id="w3" strokeWidth="5" d="M70 72L61.75 75.5L53.5 79L45.25 82.5L37 86">
                    <animate
                            attributeName="d"
                            dur="2s"
                            repeatCount="indefinite"
                            values="M70 72L62 75L53 83L43.5 80.5L37 86; M70 72L61.75 75.5L53.5 79L45.25 82.5L37 86; M70 72L62 75L53 83L43.5 80.5L37 86; M70 72L61.75 75.5L53.5 79L45.25 82.5L37 86; M70 72L62 75L53 83L43.5 80.5L37 86; M70 72L61.75 75.5L53.5 79L45.25 82.5L37 86; M70 72L62 75L53 83L43.5 80.5L37 86; M70 72L61.75 75.5L53.5 79L45.25 82.5L37 86; M70 72L61.75 75.5L53.5 79L45.25 82.5L37 86 "
                            keyTimes="0; 0.04; 0.08; 0.12; 0.16; 0.2; 0.24; 0.28; 1"
                            />
                    </path>
                    <path id="w1" strokeWidth="5" d="M37 53L45.25 56.5L53.5 60L61.75 63.5L70 67">
                        <animate
                            attributeName="d"
                            dur="2s"
                            repeatCount="indefinite"
                            values="M37 53L46 54L52 62L62.5 61.5L70 67; M37 53L45.25 56.5L53.5 60L61.75 63.5L70 67; M37 53L46 54L52 62L62.5 61.5L70 67; M37 53L45.25 56.5L53.5 60L61.75 63.5L70 67; M37 53L46 54L52 62L62.5 61.5L70 67; M37 53L45.25 56.5L53.5 60L61.75 63.5L70 67; M37 53L46 54L52 62L62.5 61.5L70 67; M37 53L45.25 56.5L53.5 60L61.75 63.5L70 67; M37 53L45.25 56.5L53.5 60L61.75 63.5L70 67 "
                            keyTimes="0; 0.04; 0.08; 0.12; 0.16; 0.2; 0.24; 0.28; 1"
                            />
                    </path>
                    <path id="w2" strokeWidth="5" d="M30 70L40 70L50 70L60 70L70 70">
                    <animate
                            attributeName="d"
                            dur="2s"
                            repeatCount="indefinite"
                            values="M30 70L40.5 67.5L50 72L60.5 69L70 70; M30 70L40 70L50 70L60 70L70 70; M30 70L40.5 67.5L50 72L60.5 69L70 70; M30 70L40 70L50 70L60 70L70 70; M30 70L40.5 67.5L50 72L60.5 69L70 70; M30 70L40 70L50 70L60 70L70 70; M30 70L40.5 67.5L50 72L60.5 69L70 70; M30 70L40 70L50 70L60 70L70 70; M30 70L40 70L50 70L60 70L70 70 "
                            keyTimes="0; 0.04; 0.08; 0.12; 0.16; 0.2; 0.24; 0.28; 1"
                            />
                    </path>
                </g>
            </defs>
            <motion.rect width="140" height="140" animate={isDark ? 'dark' : 'light'} variants={variantsBackgroundColor} strokeWidth="0"/>
            <use href="#left-whiskers" />
              <use href="#left-whiskers" transform="scale(-1 1) translate(-140 0)" />
        <motion.circle cx="70" cy="70" r="5" stroke="none" animate={isDark ? 'dark' : 'light'} variants={ variantsFillColor }></motion.circle>
        </Svg>
    </LogoContainer>
  )
}

export default Logo